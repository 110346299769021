/**
 * Translates the item's `labelId` into text, also update the selected status
 * 
 * @param {Object} currentItem The item data
 * @returns {Object}
 */
function translateAndSelectItem(currentItem, formatMessage) {
  if (!currentItem || !formatMessage) {
    return {};
  }

  const updatedItem = currentItem;
  // Depending on the page URL select the item or not
  updatedItem.isSelected = window.location.pathname.startsWith(updatedItem.url);

  const translatedText = formatMessage({
    id: updatedItem.labelId,
    defaultMessage: updatedItem.fallbackText
  });

  updatedItem.text = translatedText;

  return updatedItem;
}

/**
 * Translates the `labelId` of all the items inside `primary` and `secondary` arrays
 * 
 * @param {Object} itemsData The `items` data with the `primary` and `secondary` arrays as properties
 * @param {function} formatMessage The `intl.formatMessage` function that "translates" the `labelId` into text
 * @returns {Object}
 */
export function addTranslationsToItems(itemsData, formatMessage) {
  if (!formatMessage || !itemsData?.primary || !itemsData?.secondary) {
    return {};
  }

  const updatedItemsData = {};
  // Go through `primary` and `secondary`
  Object.keys(itemsData).forEach(groupName => {
    updatedItemsData[groupName] = [];
    
    // Go through each "group" item
    itemsData[groupName].forEach(currentItem => {
      const updatedItem = translateAndSelectItem(currentItem, formatMessage);

      // Go through each item's sub-items
      if (updatedItem.subItems) {
        const updatedSubItems = [];

        updatedItem.subItems.forEach(currentSubItem => {
          updatedSubItems.push(translateAndSelectItem(currentSubItem, formatMessage));
        });

        updatedItem.subItems = updatedSubItems;
      }

      updatedItemsData[groupName].push(updatedItem);
    });
  });

  return itemsData;
}

/**
 * Changes which item should be in the `selected` state (after routing change)
 * 
 * @param {Array} list The items array
 * @param {string} pathName The target path name
 * @returns {Array}
 */
export function updateSelectedItemFromRouter(list, pathName) {
  const updatedList = [];

  if (pathName && list?.length > 0) {
    list.forEach(currentItem => {
      const updatedItem = currentItem;

      // Only change visually if the item is not a link, if it's expandable it does not have the `url` property
      updatedItem.isSelected = pathName.startsWith(currentItem.url);

      // If it has sub-items we do it recursively
      if (currentItem.subItems?.length > 0) {
        updatedItem.subItems = updateSelectedItemFromRouter(currentItem.subItems, pathName);

        // If it has one sub-item selected, expand the current item (parent to the sub-items)
        const itemHasASelectedSubItem = updatedItem.subItems.some(subItem => subItem.isSelected);
        if (itemHasASelectedSubItem) {
          updatedItem.isOpen = true;
        }
      }

      updatedList.push(updatedItem);
    });
  }

  return updatedList;
};

/**
 * If the help menu items exist, return the `Navigation items`-like strucuture
 * 
 * @param {object[]} items The help menu items array
 * @param {string} (Optional) helpTextTranslation The `Help` label that appears as "parent" of the help menu items
 * 
 * @returns object | null
 */
export function getHelpMenuItemData(items, helpTextTranslation) {
  if (items) {
    return {
      id: "help-header",
      text: helpTextTranslation || "Help",
      icon: "helpOutline",
      isSelected: false,
      isOpen: true,
      subItems: items
    };
  }

  return null;
};
