/* eslint react/jsx-filename-extension: 0 */
import 'core-js/stable';
import 'intl';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import * as styled from 'styled-components';
import NotificationContext from 'og-merchant-portal-react-library/lib/NotificationContext/NotificationContext';
import FetchContext from 'og-merchant-portal-react-library/lib/FetchContext';
import { UserInfoContext } from 'og-merchant-portal-react-library';

import Bootstrap from './Bootstrap';
import { unregister } from './registerServiceWorker';
import userPreferences from './utils/userPreferences';
import translations from './utils/translationApi';

window.SharedLibraries = {
  React,
  ReactDOM,
  moment,
  NotificationContext,
  FetchContext,
  UserInfoContext,
  'styled-components': styled
};

const { lang: userLang, region: userRegion } = userPreferences.getPreSelectedLocaleOrDefault();
const availableTranslations = translations.getAvailableTranslationsList();

Promise.all([
  fetch("/themes/theme.json", { cache: 'no-cache' }).then(response => response.json()),
  translations.getAllFor(userLang)
])
  .then(([theme, messages]) => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
      <Bootstrap
        translatedMessages={messages}
        locale={userLang}
        region={userRegion}
        availableTranslations={availableTranslations}
        theme={theme}
      />
    );
    unregister();
  })
  .catch(error => {
    console.error("The portal theme or the translations could not be loaded. Please retry later.");
    throw new Error(error);
  });
