import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  AppDrawer,
  Drawer,
  TopMenuWithOverlayPanel,
  UserInfoContext
} from 'og-merchant-portal-react-library';

import httpClient from '../utils/httpClient/httpClient';
import SessionTimeoutModal from './common/SessionTimeoutModal';
import Notification from './common/NotificationContext';
import FeedBackButton from './common/FeedbackButton';
import NavigationRoutes from './NavigationRoutes';
import composition from '../utils/appComposition';
import LanguageSelector from './menu/LanguageSelector';
import {
  addTranslationsToItems,
  updateSelectedItemFromRouter
} from '../utils/navigationItems/navigationItems';
import formatAppDrawerItems from '../utils/formatAppDrawerItems/formatAppDrawerItems';
import { APP_DRAWERS_MERCHANT_PORTAL_APP_ID } from '../utils/constants';
import { StyledAppContainer, StyledPageContainer } from './App.styled';
import getKeycloak from '../keycloak';
import Footer from './common/Footer';
import extractModuleNameAndPrependSlash from '../utils/extractModuleNameAndPreprendSlash';
import getBaseRoutes from '../utils/getBaseRoutes';

class App extends React.PureComponent {
  externalWidgets = {};

  state = {
    modules: null,
    baseRoutes: [],
    isDrawerExpanded: true,
    items: {},
    appDrawerItems: []
  };

  logoutButton = 'logoutButton';

  componentDidMount() {
    const { history } = this.props;

    if (!window.appSettings.useModuleManifests) {
      this.legacyLoadNavigationAndModules();
    } else {
      Promise.resolve(httpClient.get(`/api/rbmp/modules`, `ciam`))
        .then(modules => this.loadModules(modules))
        .then(loadedModules => this.setState({ modules: loadedModules }))
        .catch(() => {
          // Basic fallback data (as precaution)
          this.setState(
            {
              items: {
                primary: [],
                secondary: []
              }
            },
            () => this.updateSelectedItemOnNavigation(history.location.pathname)
          );
        });
    }

    httpClient.get(`/api/rbmp/app-drawer`, `ciam`).then(
      response => {
        this.setState({
          appDrawerItems: formatAppDrawerItems(response)
        });
      },
      error => {
        this.setState({
          appDrawerItems: []
        });
        console.error('Something went wrong while fetching app drawer items: ', error);
      }
    );

    // Detect routing changes to update the select item of the navigation
    history.listen(routerChange => {
      this.updateSelectedItemOnNavigation(routerChange.pathname);
    });
  }

  loadModules = navigationAndModules => {
    const { intl, history, addTranslations, locale } = this.props;
    const itemsDataWithPrimaryAndSecondaryItems = {
      primary: navigationAndModules.navigation,
      secondary: []
    };

    const baseRoutes = navigationAndModules.modules.map(mod => ({
      baseRoute: mod.baseRoute,
      bundlesFolder: mod.bundlesFolder
    }));

    this.setState(
      {
        items: addTranslationsToItems(itemsDataWithPrimaryAndSecondaryItems, intl.formatMessage),
        baseRoutes
      },
      () => this.updateSelectedItemOnNavigation(history.location.pathname)
    );

    const bundlesToLoad = baseRoutes.map(route => route.bundlesFolder);
    return composition.loadBundles([...new Set(bundlesToLoad)], locale, addTranslations, 10000);
  };

  legacyLoadNavigationAndModules = () => {
    const { locale, addTranslations, intl, history } = this.props;
    Promise.all([httpClient.get('/user/navigation-items', `ogn`), getBaseRoutes()])
      .then(([itemsData, baseRoutes]) => {
        const newBaseRoutes = baseRoutes.map(mod => ({
          baseRoute: mod.path,
          bundlesFolder: mod.module
        }));
        const itemsDataWithPrimaryAndSecondaryItems = {
          primary: itemsData,
          secondary: []
        };
        const allowedUrls = itemsDataWithPrimaryAndSecondaryItems.primary
          .map(item => {
            if (item.url) {
              return extractModuleNameAndPrependSlash(item.url);
            }

            const allowedBaseRoutes = item.subItems.map(subItem => {
              return extractModuleNameAndPrependSlash(subItem.url);
            });

            return [...new Set(allowedBaseRoutes)];
          })
          .flat();
        const filteredBaseRoutes = newBaseRoutes.filter(moduleRoute =>
          allowedUrls.includes(moduleRoute.baseRoute)
        );
        const modulesToLoad = filteredBaseRoutes.map(baseRoute => baseRoute.bundlesFolder);

        this.setState(
          {
            items: addTranslationsToItems(
              itemsDataWithPrimaryAndSecondaryItems,
              intl.formatMessage
            ),
            baseRoutes: filteredBaseRoutes
          },
          () => this.updateSelectedItemOnNavigation(history.location.pathname)
        );
        return composition.loadModules(10000, modulesToLoad);
      })
      .then(modules => {
        this.setState({ modules });
        return composition.loadTranslations(locale, addTranslations);
      })
      .catch(() => {
        // Basic fallback data (as precaution)
        this.setState(
          {
            items: {
              primary: [],
              secondary: []
            }
          },
          () => this.updateSelectedItemOnNavigation(history.location.pathname)
        );
      });
  };

  updateSelectedItemOnNavigation = pathName => {
    const { items } = this.state;
    const { primary, secondary } = items;

    // Visually select/deselect the items, only for non-external links
    if (pathName?.search(/^http/gi) === -1) {
      const updatedPrimary = updateSelectedItemFromRouter(primary, pathName);
      const updatedSecondary = updateSelectedItemFromRouter(secondary, pathName);

      this.setState({
        items: {
          primary: updatedPrimary,
          secondary: updatedSecondary
        }
      });
    }

    /**
     * We need to reset the active active element, so we after using the browser's
     * back button we don't have the incorrect item as active
     */
    document.activeElement.blur();
  };

  onDrawerExpandCollapse = isExpanded => {
    this.setState({
      isDrawerExpanded: isExpanded
    });
  };

  onNavigationItemClick = targetItem => {
    const { items } = this.state;

    if (items && targetItem) {
      // Go to a new route / new page
      if (targetItem.url) {
        if (targetItem.url === this.logoutButton) {
          getKeycloak().logout();
        } else if (targetItem.url.search(/^http/gi) !== -1) {
          // Link to outside the Merchant Portal
          window.location.assign(targetItem.url);
        } else {
          // Link to inside the Merchant Portal
          // eslint-disable-next-line react/destructuring-assignment
          this.props.history.push(targetItem.url);
        }
      }
    }
  };

  render() {
    const { modules, items, isDrawerExpanded, appDrawerItems, baseRoutes } = this.state;
    const { locale, theme, intl, availableTranslations } = this.props;

    const translatedLogoutLinkLabel = intl.formatMessage({
      id: 'global.menu.logout',
      defaultMessage: 'Logout'
    });

    const logoLinkTitle = theme.brandingTitle;

    const appDrawerComponent =
      appDrawerItems.length > 0 ? (
        <AppDrawer
          domIdToAppend="app-drawer-root"
          title={intl.formatMessage({
            id: 'global.menu',
            defaultMessage: 'Menu'
          })}
          appDrawerItems={appDrawerItems}
          activeItemId={APP_DRAWERS_MERCHANT_PORTAL_APP_ID}
        />
      ) : null;

    return (
      <>
        <StyledAppContainer className="App">
          {items.primary ? (
            <>
              <TopMenuWithOverlayPanel
                items={items}
                languageSelectorComponent={
                  availableTranslations?.length > 1 ? <LanguageSelector /> : null
                }
                onNavigationItemClick={this.onNavigationItemClick}
                rightLogoutLinkUrl={this.logoutButton}
                rightLogoutLinkLabel={translatedLogoutLinkLabel}
                logoUrl="/themes/logo.svg"
                title={logoLinkTitle}
                appDrawerComponent={appDrawerComponent}
              />

              <Notification />

              <SessionTimeoutModal sessionTimeoutValue={900}>
                <>
                  <Drawer
                    title={logoLinkTitle}
                    items={items}
                    logoUrl="/themes/logo.svg"
                    onExpandCollapse={this.onDrawerExpandCollapse}
                    onNavigationItemClick={this.onNavigationItemClick}
                  />

                  <StyledPageContainer isDrawerExpanded={isDrawerExpanded}>
                    <NavigationRoutes
                      modules={modules}
                      locale={locale}
                      theme={theme}
                      items={items.primary}
                      baseRoutes={baseRoutes}
                    />
                  </StyledPageContainer>
                </>
              </SessionTimeoutModal>
              <FeedBackButton url={window.appSettings.feedbackButtonUrl} />
            </>
          ) : null}
        </StyledAppContainer>
        {items.primary ? <Footer isDrawerExpanded={isDrawerExpanded} locale={locale} /> : null}
      </>
    );
  }
}

App.contextType = UserInfoContext;

App.propTypes = {
  /** The locale (for example: `en`) */
  locale: PropTypes.string.isRequired,

  /** The theme object from our custom `ThemeProvider` */
  theme: PropTypes.object.isRequired,

  /** Function to add translations */
  addTranslations: PropTypes.func.isRequired,

  /** `I18nProvider`'s object */
  intl: PropTypes.object.isRequired,

  /** List of avaliables languages */
  availableTranslations: PropTypes.array.isRequired,

  /** React router's history */
  history: PropTypes.object.isRequired
};

export default injectIntl(withRouter(withTheme(App)));
