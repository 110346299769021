import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UserInfoContext } from 'og-merchant-portal-react-library';

import getKeycloak from '../../../keycloak';


const UserInfoProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const keycloak = getKeycloak();
    if (keycloak.tokenParsed !== undefined && keycloak.tokenParsed !== null) {
      const tokenUser = {
        userUuid: keycloak.tokenParsed.sub,
        userFirstName: keycloak.tokenParsed.given_name,
        userLastName: keycloak.tokenParsed.family_name,
        userEmail: keycloak.tokenParsed.email,
        userSession: keycloak.tokenParsed.sid
      };

      setUser(previousUser => ({ ...previousUser, ...tokenUser }));
    }
  }, []);


  const value = {
    user,
    setUser: userData => {
      setUser(prevState => ({ ...prevState, ...userData }));
    }
  };

  return <UserInfoContext.Provider value={value}>{children}</UserInfoContext.Provider>;
};

UserInfoProvider.propTypes = {
  /** The React children */
  children: PropTypes.node.isRequired
};

export default UserInfoProvider;
