import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { SpinnerLoading } from 'og-merchant-portal-react-library';

import getKeycloak, { instantiateKeycloak } from '../keycloak';

const StyledLoadingContainer = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KeycloakInitializer = ({ children }) => {
  const [authenticated, setAuthenticated] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  useEffect(() => {
    if (!initialized) {
      fetch(`${window.appSettings.apiBaseUrl}/api/whitelabeling/authentication-settings/${window.appSettings.apiBaseUrl.replace("https://", "")}`, {
        credentials: 'include',
        headers: {
          'X-Rbmp-Use-Public-Client': 'true',
          'X-Rbmp-Tenant': 'ciam'
        }
      }).then(response => response.json())
        .then(responseJson => {
          const keycloakConfig = {
            url: responseJson.authServerUrl,
            realm: responseJson.realm,
            clientId: responseJson.clientId,
          };

          const instantiatedKeycloak = instantiateKeycloak(keycloakConfig);

          return instantiatedKeycloak.init({
            onLoad: 'login-required',
            checkLoginIframe: false
          });
        })
        .then(result => setAuthenticated(result))
        .catch(error => {
          setAuthenticated(false);
          console.error(error);
        })
        .finally(() => setInitialized(true));
    } else {
      setInitialized(true);
      setAuthenticated(true);
    }

    // eslint-disable-next-line
  }, []);

  if (!initialized) {
    return (
      <StyledLoadingContainer>
        <SpinnerLoading />
      </StyledLoadingContainer>
    );
  }

  const keycloak = getKeycloak();
  if (!authenticated || keycloak?.tokenParsed === undefined || keycloak?.tokenParsed === null) {
    return <FormattedMessage id="global.error" defaultMessage="An error has occurred." />;
  }

  return children;
};

KeycloakInitializer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};

export default KeycloakInitializer;
