import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import NotificationContext from 'og-merchant-portal-react-library/lib/NotificationContext/NotificationContext';

const ErrorFallback = React.memo(() => {
  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    addNotification({
      type: 'error',
      text: <FormattedMessage id="global.error" defaultMessage="An error has occurred." />
    });
  // eslint-disable-next-line
  }, []);

  return <FormattedMessage id="global.error" defaultMessage="An error has occurred." />;
});

export default ErrorFallback;
