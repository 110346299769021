import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStylesInjector } from '@worldline/gaia';

import App from './components/App';
import KeycloakInitializer from './components/KeycloakInitializer';
import I18nProvider from './components/common/internationalization/I18nProvider';
import NotificationProvider from './components/common/NotificationContext/NotificationProvider';
import UserInfoProvider from './components/common/user-info/UserInfoProvider';
import httpClient from './utils/httpClient/httpClient';
import FetchContextProvider from './utils/FetchContextProvider';
import { CookieLogoutTimeoutProvider } from './utils/CookieLogoutTimeoutContext';
import I18nContext from './components/common/internationalization/I18nContext';

const Bootstrap = ({ locale, translatedMessages, availableTranslations, theme, region }) => (
  <ThemeProvider theme={theme}>
    <KeycloakInitializer>
      <I18nProvider
        locale={locale}
        region={region}
        translatedMessages={translatedMessages}
        availableTranslations={availableTranslations}
      >
        <NotificationProvider>
          <CookieLogoutTimeoutProvider>
            <FetchContextProvider fetcher={httpClient}>
              <UserInfoProvider>
                <BrowserRouter>
                  <I18nContext.Consumer>
                    {({ addTranslations, locale: localeFromContext }) => (
                      <App
                        availableTranslations={availableTranslations}
                        addTranslations={addTranslations}
                        locale={localeFromContext}
                      />
                    )}
                  </I18nContext.Consumer>
                </BrowserRouter>
                <GlobalStylesInjector />
              </UserInfoProvider>
            </FetchContextProvider>
          </CookieLogoutTimeoutProvider>
        </NotificationProvider>
      </I18nProvider>
    </KeycloakInitializer>
  </ThemeProvider>
);

Bootstrap.propTypes = {
  locale: PropTypes.string.isRequired,
  translatedMessages: PropTypes.object,
  availableTranslations: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired
};

Bootstrap.defaultProps = {
  translatedMessages: {},
  availableTranslations: ['en']
};

export default Bootstrap;
