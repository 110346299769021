import React from 'react';
import FetchContext from 'og-merchant-portal-react-library/lib/FetchContext';
import PropTypes from "prop-types";

const FetchContextProvider = ({ fetcher, children }) => {
  return (
    <FetchContext.Provider value={{
      fetcher: {
        ...fetcher,
        httpClient: fetcher
      }
    }}
    >
      {children}
    </FetchContext.Provider>
  );
};

FetchContextProvider.propTypes = {
  fetcher: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default FetchContextProvider;
