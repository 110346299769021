/**
 * Receives the (unorganized) external modules (micro-frontends) and returns an object with data organized by module name
 * 
 * @param {Object[]} modules The (unorganized) modules data
 * @returns {Object} The organized modules data as a object
 */
function createLegacyExternalWidgets(modules) {
  const externalWidgets = {};

  modules.forEach(module => {
    externalWidgets[module.getName()] = module.default;
  });

  return externalWidgets;
};

export default createLegacyExternalWidgets;
