import Keycloak from 'keycloak-js';

// eslint-disable-next-line
let keycloak = null;

export const instantiateKeycloak = keycloakConfig => {
    keycloak = new Keycloak(keycloakConfig);
    return keycloak;
};

const getKeycloak = () => { return keycloak; };

export default getKeycloak;

