import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Styled, { keyframes, ThemeContext } from 'styled-components';

const sliding = keyframes`
    0%{
        transform: translateY(-200px) translateZ(0);
    }
    15%{
        transform: translateY(0) translateZ(0);
    }
    90%{
        transform: translateY(0) translateZ(0);
    }
    100%{
        transform: translateY(-200px) translateZ(0);
    }
`;

const StyledContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  top: 5rem;
  left: 1.6rem;
  right: 1.6rem;
  max-width: 46rem;
  margin: auto;
  padding: 2rem;
  border: 1px solid ${({ type }) => type.borderColor};
  border-radius: 0.4rem;
  background-color: ${({ type }) => type.backgroundColor};
  text-align: left;
  z-index: 90;
  transform: translateY(-200px) translateZ(0);
  animation: ${({ time }) => time}s ${sliding} 1 ease-in-out;
  color: ${({ type }) => type.fontColor};
  font-size: ${({ theme }) => theme.font.sizes.md};
  font-weight: ${({ theme }) => theme.font.weights.regular};
  cursor: default;

  svg {
    width: 1.5rem;
    height: 1.7rem;
    line-height: inherit;
    margin-right: 0.2rem;
  }
  
  span {
    color: ${({ type }) => type.fontColor};
    font-size: ${({ theme }) => theme.font.sizes.md};
  }
`;

const Toast = ({ type, time, children }) => {
  const theme = useContext(ThemeContext);

  const typeStyles = {
    error: {
      backgroundColor: theme.colors.error.light,
      borderColor: theme.colors.error.regular,
      fontColor: theme.colors.error.regular
    },
    success: {
      backgroundColor: theme.colors.success.light,
      borderColor: theme.colors.success.regular,
      fontColor: theme.colors.success.regular
    },
    info: {
      backgroundColor: theme.colors.info.light,
      borderColor: theme.colors.info.regular,
      fontColor: theme.colors.info.regular
    },
    warning: {
      backgroundColor: theme.colors.warning.light,
      borderColor: theme.colors.warning.regular,
      fontColor: theme.colors.warning.regular
    }
  };

  return (
    <StyledContainer data-test="global_toast" time={time} type={typeStyles[type]} theme={theme}>
      {children}
    </StyledContainer>
  );
};

Toast.propTypes = {
  /** The type of notification (can be success, error, info) */
  type: PropTypes.oneOf(['success', 'error', 'info', 'warning']).isRequired,

  /** The React children */
  children: PropTypes.node.isRequired,

  /** The time/duration (in seconds) that the notification should be shown */
  time: PropTypes.number
};

Toast.defaultProps = {
  time: 4
};

export default Toast;
