import React from 'react';
import PropTypes from 'prop-types';

import { StyledFooter, StyledQuickLink } from './StyledFooter';

const Footer = ({ locale, isDrawerExpanded }) => {
  const quickLinks = window.appSettings?.helpMenu ? window.appSettings.helpMenu[locale] : null;

  if (!quickLinks) {
    return null;
  }

  return (
    <StyledFooter isDrawerExpanded={isDrawerExpanded} data-test="footer_quicklinks">
      {quickLinks.map(({ text, url, id}) => (
        <StyledQuickLink href={url} target="_blank" rel="noopener noreferrer" key={id} data-test={`footer_quicklinks_${id}`}>
          {text}
        </StyledQuickLink>
      ))}
    </StyledFooter>
  );
};

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
  isDrawerExpanded: PropTypes.bool.isRequired
};

export default Footer;
