import React from 'react';
import { injectIntl } from 'react-intl';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { TextV2 } from 'og-merchant-portal-react-library';

import NoAccessLogo from './NoAccessLogo';

const StyledTitleContainer = styled.div`
  margin-bottom: 1.2rem;
  span {
    color: ${props => props.theme.colors.greyScale.body};
  }
`;

const StyledSubTitleContainer = styled.div`
  margin-bottom: 2.8rem;
  span {
    color: ${props => props.theme.colors.greyScale.subText};
  }
`;

const StyledContainer = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLogoContainer = styled.div`
  margin-bottom: 2rem;
`;

export const NoAccessPageCustom = ({ theme, intl }) => {

  const title = intl.formatMessage({
    id: 'global.noAccess.title',
    defaultMessage: 'You do not currently have access to a valid processing account.'
  });

  const subTitle = intl.formatMessage({
    id: 'global.noAccess.subTitle',
    defaultMessage: 'Please contact your merchant admin or customer care.'
  });

  return (
    <StyledContainer data-test="no-access-page">
      <StyledLogoContainer>
        <NoAccessLogo />
      </StyledLogoContainer>
      <StyledTitleContainer>
        <TextV2 weight="semibold" color={theme.colors.greyScale.body} size="h5">
          {title}
        </TextV2>
      </StyledTitleContainer>

      <StyledSubTitleContainer>
        <TextV2 weight="regular" size="lg">
          {subTitle}
        </TextV2>
      </StyledSubTitleContainer>
    </StyledContainer>
  );
};

NoAccessPageCustom.propTypes = {
  /** The theme object from our custom `ThemeProvider` */
  theme: PropTypes.object.isRequired,

  /** `I18nProvider`'s object */
  intl: PropTypes.object.isRequired
};

export default injectIntl(withTheme(NoAccessPageCustom));
