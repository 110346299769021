import { FormattedMessage } from 'react-intl';
import React from 'react';

const formatAppDrawerItems = userApplications => {
  return userApplications.map(userApplication => {
    return {
      ...userApplication,
      title: (
        <FormattedMessage
          id={`global.appDrawer.application.${userApplication.id}.name`}
          defaultMessage={`${userApplication.id} name`}
        />
      ),
      description: (
        <FormattedMessage
          id={`global.appDrawer.application.${userApplication.id}.description`}
          defaultMessage={`${userApplication.name} description`}
        />
      ),
      url: userApplication.link
    };
  });
};

export default formatAppDrawerItems;
