import { loadScript } from '../libLoader';
import httpClient from '../httpClient/httpClient';

const loadedModuleDefintions = [];

function getWidgetsUrl() {
  return httpClient.getSetting('widgetsUrl');
}

async function getModuleDefinitions() {
  const url = `${getWidgetsUrl()}/modules.json`;
  
  return fetch(url, { cache: 'no-cache' })
    .then(response => {
      return response.json();
    })
    .then(result => {
      return Promise.resolve(result.modules);
    })
    .catch(error =>
      console.error({
        msg: `An error occured when trying to load the micro apps from ${url}`,
        error
      })
    );
}

const loadJsBundle = (definition, timeout) => {
  const url = `${getWidgetsUrl()}${definition.relativePath}/main.bundle.js?v=${
    definition.version
  }`;
  return loadScript(url, timeout)
    .then(() => {
      // loaded module should be accessible in the global object
      if (!global[definition.module]) {
        console.error(
          `Loading module ${definition.module} failed. Ensure your package name (package.conf)` +
            ` matches module name in the deployed definition file (modules.json)`
        );
      } else if(!loadedModuleDefintions.find(loadedModule => loadedModule.module === definition.module)) {
        loadedModuleDefintions.push(definition);
      }
    })
    .catch(error =>
      console.error(`Loading module ${definition.module} failed: ${JSON.stringify(error)}`)
    );
};

const getAvailableModules = () =>
  loadedModuleDefintions.map(d => {
    global[d.module].getName = () => d.module;
    global[d.module].getDeployedPath = () => `${getWidgetsUrl()}${d.relativePath}`;
    return global[d.module];
  });

const loadModules = async (timeout, modulesToLoad) => {
  return getModuleDefinitions()
    .then(moduleDefinitions => {
      const moduleDefinitionsToLoad = moduleDefinitions.filter(moduleDefinition => modulesToLoad.includes(moduleDefinition.module));
      return Promise.all(moduleDefinitionsToLoad.map(definition => loadJsBundle(definition, timeout)));
    })
    .then(() => getAvailableModules());
};

const loadTranslation = async (bundle, locale) => {
  const url = `${getWidgetsUrl()}${bundle}/assets/${locale}.json`;
  return fetch(url, { cache: 'no-cache' }).then(response => response.json());
};

const loadTranslations = async (locale, addTranslations) => {
  await loadedModuleDefintions.forEach(async definition => {
    try {
      const result = await loadTranslation(definition.relativePath, locale);
      addTranslations(result);
    } catch (err) {
      console.log(`An issue happened while loading the translation for ${definition} : ${locale}`);
      console.log(err);
    }
  });
};

const loadJsBundleFiles = async (bundle, version, timeout) => {
  const url = `${getWidgetsUrl()}/${bundle}/main.bundle.js?v=${version}`;
  return loadScript(url, timeout)
          .then(() => {
            // loaded module should be accessible in the global object
            if (!global[bundle]) {
              console.error(
                `Loading module ${bundle} failed. Ensure your package name (package.conf)` +
                  ` matches module name in the deployed definition file (modules.json)`
              );
            } else if(!loadedModuleDefintions.find(loadedModule => loadedModule === bundle)) {
              loadedModuleDefintions.push(bundle);
            }
          })
          .catch(error =>
            console.error(`Loading module ${bundle} failed: ${JSON.stringify(error)}`)
          );
};

const getLoadedBundles = () =>
  loadedModuleDefintions.map(d => {
    global[d].getName = () => d;
    global[d].getDeployedPath = () => `${getWidgetsUrl()}/${d}`;
    return global[d];
  });

async function getBundlesDefinition() {
  const url = `${getWidgetsUrl()}/bundles.json`;

  return fetch(url, { cache: 'no-cache' })
    .then(response => {
      return response.json();
    })
    .catch(error =>
      console.error({
        msg: `An error occured when trying to load the bundles definition file from ${url}`,
        error
      })
    );
}

const loadBundles = async (bundlesToLoad, locale, addTranslations, timeout) => {
  return getBundlesDefinition()
    .then(async bundlesDefinition =>
            Promise
            .all(bundlesToLoad.map(bundle => {
              const bundleVersion = bundlesDefinition.find(bd => bd.bundlesFolder === bundle)?.version;
              return loadJsBundleFiles(bundle, bundleVersion, timeout);
            }))
          )
          .then(
            await bundlesToLoad.forEach(async bundle => {
            try {
              const result = await loadTranslation(`/${bundle}`, locale);
              addTranslations(result);
            } catch (err) {
              console.log(`An issue happened while loading the translation for ${bundle} : ${locale}`);
              console.log(err);
            }
          })
        )
          .then(() => getLoadedBundles());
};

export default { loadModules, loadTranslations, loadBundles };
